import { combineReducers } from "redux";

import SchoolsSlice from "./Schools/SchoolReducer";
import MajorSlice from "./Major/MajorReducer";
const RootReducers = combineReducers({
    SchoolReducer:SchoolsSlice.reducer,
    MajorReducer:MajorSlice.reducer,
});

export default RootReducers;
