import React, { useState, useEffect } from "react";
import "./SinglePage.css";

import imagehead from "./../../assets/images/singlepage/headingc.png";
import imagehead1 from "./../../assets/images/singlepage/bg1.png";
import profile from "./../../assets/images/singlepage/Path 786.png";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import facebook from "./../../assets/images/singlepage/Group 55.png";
import linked from "./../../assets/images/singlepage/Group 57.png";
import instagram from "./../../assets/images/singlepage/Group 56.png";
import { BsPhone } from "react-icons/bs";
import { IoSettingsOutline } from "react-icons/io5";
import { FaSchool } from "react-icons/fa";
import { CiLocationOn } from "react-icons/ci";
import MajorCard from "../Component/MajorCard/MajorCard";
import { useDispatch, useSelector } from "react-redux";
import {
  getSChool,
  getgallerySchool,
} from "./../../Redux/Schools/SchoolAction";
import Loader from "../Loader/Loader";
import { IoMailOutline } from "react-icons/io5";
import { BsBrowserChrome } from "react-icons/bs";
import white from "./../../assets/images/singlepage/NSDL77J3KJFZXCK3MFWAV7HMUE.avif";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useSSR, useTranslation } from "react-i18next";
import i18n from "../../i18n";

function SinglePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const location = useLocation();
  var url = window.location.pathname;

  // Extract the ID from the URL path
  var id = url.split("/")[2];
  const { school, loading4, gallery } = useSelector(
    (store) => store.SchoolReducer
  );
  useEffect(() => {
    dispatch(getSChool(id));
    dispatch(getgallerySchool(id));
  }, []);
  const [schooldata, setSchooldata] = useState("");
  const [showmore, setShowmore] = useState(false);
  useEffect(() => {
    if (school) {
      setSchooldata(school);
    }
  }, [school]);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top of the page
  }, []);

  const handleClick = (event) => {
    event.preventDefault();

    const targetUrl = event.currentTarget.getAttribute("href");

    if (targetUrl) {
      window.location.href = targetUrl;
    }
  };
  
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top of the page
  }, []);

  const handleShowMore = () => {
    setShowmore(!showmore);
  };

  if (loading4) {
    return <Loader />;
  } else {
    return (
      <div className="single-page">
        <div className="bothpic">
          <img src={profile} className="profile" />
          <img src={imagehead} className="fist-image" />
          <img
            src={
              process.env.REACT_APP_API +
              "/school/" +
              schooldata?.data?.data?.logo_url
            }
            className={lang == "en" ? "img-profile" : "img-profile_ar"}
          />
        </div>
        <div className={lang == "en" ? "secondcont" : "secondcontar"}>
          <div className="title">
            <div className="title-first">
              {lang == "en" && (
                <div>{schooldata?.data?.data?.institution_name_en}</div>
              )}
              {lang == "ar" && (
                <div>{schooldata?.data?.data?.institution_name_ar}</div>
              )}
            </div>

            <div className={lang == "en" ? "set-linked" : "set-linkedar"}>

              {/* <a href="#">LinkedIn</a> */}
              <a
                href={schooldata?.data?.data?.facebook_link}
                className="linked"
                onClick={handleClick}
              >
                <img src={facebook} className="linked" />
              </a>

              <a
                href={schooldata?.data?.data?.instagram_link}
                className="linked"
                onClick={handleClick}
              >
                <img src={instagram} className="linked" />
              </a>

              <a
                href={schooldata?.data?.data?.Linkedin_profile}
                className="linked"
                onClick={handleClick}
              >
                <img src={linked} className="linked" />
              </a>

            </div>
          </div>
          <div className="setofdetails">
            <div className="datials">
              <div className="item">
                <BsPhone /> {schooldata?.data?.data?.phone}{" "}
              </div>
              <div className="item">
                <CiLocationOn />
                {lang == "en" && (
                  <div>{schooldata?.data?.data?.district?.name}</div>
                )}
                {lang == "ar" && (
                  <div>{schooldata?.data?.data?.district?.name_ar}</div>
                )}
              </div>
            </div>
            <div className="datials">
              <div className="item">
                <IoMailOutline /> {schooldata?.data?.data?.email}{" "}
              </div>
              <div className="item">
                <IoSettingsOutline />
                {lang == "en" && (
                  <div>{schooldata?.data?.data?.institution_type_en}</div>
                )}
                {lang == "ar" && (
                  <div>{schooldata?.data?.data?.institution_type_ar}</div>
                )}
              </div>
            </div>
            <div className="datials">
              <div className="item">
                <BsBrowserChrome />{" "}
                <a href={schooldata?.data?.data?.Website_link}>
                  {schooldata?.data?.data?.Website_link
                    ? schooldata?.data?.data?.Website_link
                    : "Web not Available"}
                </a>
              </div>
              <div className="item">
                <FaSchool />{" "}
                {lang == "en" && (
                  <div>{schooldata?.data?.data?.institution_gender_en}</div>
                )}
                {lang == "ar" && (
                  <div>{schooldata?.data?.data?.institution_gender_ar}</div>
                )}
              </div>
            </div>
          </div>
          <div className="title-first">
            {lang == "en" && <div>About the School</div>}
            {lang == "ar" && <div>تفاصيل المدرسة</div>}
          </div>
          <div className="description">
            {lang == "en" && <p>{schooldata?.data?.data?.describe_en}</p>}
            {lang == "ar" && <p>{schooldata?.data?.data?.describe_ar}</p>}
          </div>
          <div className="title-first">
            {lang == "en" && <div>Majors</div>}
            {lang == "ar" && <div>الاختصاصات</div>}
          </div>

          <div className="majors">
            {!showmore &&
              schooldata?.data?.data?.school_major_levels
                .slice(0, 4)
                .map((level) => (
                  <MajorCard
                    key={level.level_id}
                    id={level?.major_level?.id}
                    title={
                      lang == "en"
                        ? level.major_level.major.name_en
                        : level.major_level.major.name_ar
                    }
                    name={level.major_level.level_id}
                  />
                ))}

            {showmore &&
              schooldata?.data?.data?.school_major_levels.map((level) => (
                <MajorCard
                  key={level.level_id}
                  id={level?.major_level?.id}
                  title={level.major_level.major.name_en}
                  name={level.major_level.level_id}
                />
              ))}
          </div>
          {schooldata?.data?.data?.school_major_levels?.length > 4 && (
            <div className="viwmorebutton">
              <button className="seeall" onClick={handleShowMore}>
                <div>{!showmore && <div>See all</div>}</div>

                {showmore && <div>See less</div>}
              </button>
            </div>
          )}
          {/* <div className="title-first">School Location</div> */}

          {/* <div className="Map_container">
            <LoadScript googleMapsApiKey="AIzaSyAEmTg3qjxS9QYc7YcWggM_YiqX6QW7DDU">
              <GoogleMap
                className="Map"
                center={position}
                zoom={14}
                mapContainerStyle={{ height: "600px" }}
              >
                <Marker
                  position={{
                    lat: parseFloat(schooldata?.data?.data?.location_latitude),
                    lng: parseFloat(schooldata?.data?.data?.location_longitute),
                  }}
                />
              </GoogleMap>
            </LoadScript>
          </div> */}

          <div className="title-first">Gallery</div>
          <div className="gallery">
            {gallery?.data?.data?.map((item) => (
              <img
                src={process.env.REACT_APP_API + "/gallery/" + item.img_url}
                className="image-item"
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default SinglePage;
