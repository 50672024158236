import React from "react";
import "./MajorCard.css";
import { useNavigate } from "react-router-dom";

function MajorCard(props) {
  const navigate = useNavigate();
  let renderedValue;

  if (props.name === 1) {
    renderedValue = "BP";
  } else if (props.name === 2) {
    renderedValue = "BT";
  } else if (props.name === 3) {
    renderedValue = "TS";
  } else if (props.name === 4) {
    renderedValue = "LT";
  } else if (props.name === 5) {
    renderedValue = "DS";
  } else if (props.name === 6) {
    renderedValue = "MS";
  }

  return (
    <div
      className="card-container"
      onClick={
        () => {
          navigate(`/majordetailsingle/${props.id}`);
        }
        //relaod
      }
    >
      <div className="propstitle">{props.title}</div>
      <div className="major">
        <div className="itemss">{renderedValue}</div>
      </div>
    </div>
  );
}

export default MajorCard;
