import axios from "axios";
import { MajorAction } from "./MajorReducer";

export const getMajorsss = (level_id, major_id, search) => async (dispatch) => {
  try {
    dispatch(MajorAction.majorRequest());
    let uri = process.env.REACT_APP_API + "/majorLevel/getAll?"; // Changed to 'let' instead of 'const'
    if (level_id) uri += "level_id=" + level_id + "&";

    if (major_id) uri += "major_id=" + major_id + "&";
    if (search) uri += "search=" + search;

    const res = await axios.get(uri);
    dispatch(MajorAction.majorSuccess(res));
  } catch (error) {
    dispatch(MajorAction.majorFail(error.message));
  }
};

export const getonaemajor = (id) => async (dispatch) => {
  try {
    dispatch(MajorAction.onemajorRequest());
    const res = await axios.get(
      process.env.REACT_APP_API + "/majorLevel/getOne/" + id
    );
    dispatch(MajorAction.onemajorSuccess(res));
  } catch (error) {
    dispatch(MajorAction.onemajorFail(error.message));
  }
};
