import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import PageSwitch from "./Pages/PageSwitch";
import store from "./Redux/Store";
import { Provider } from "react-redux";
function App() {
  document.body.dir = document.documentElement.lang === "ar" ? "rtl" : "ltr";
  return (
    <Router basename="/">
          <Provider store={store}>
      <PageSwitch />
      </Provider>
    </Router>
  );
}

export default App;
