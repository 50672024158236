import React, { useState, useEffect, useRef } from "react";
import "./Header.css";
import logo from "../../assets/images/header/logo.png";

import { AiOutlineSearch } from "react-icons/ai";

import { RxHamburgerMenu } from "react-icons/rx";
import BurgerMenu from "./BurgerMenu/BurgerMenu";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

function Header() {
  const ref = useRef();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  useEffect(() => {
    if (localStorage.getItem('lang') === "ar") {
      i18n.changeLanguage("ar");
      document.body.dir = "rtl";
      document.documentElement.setAttribute("lang", "ar");
      setLang("ar");
    } else {
      i18n.changeLanguage("en");
      document.body.dir = "ltr";
      document.documentElement.setAttribute("lang", "en");
      setLang("en");

    }
  },[])
  const handleLanguageChangear = () => {
    if (i18n.language === "en") {
      i18n.changeLanguage("ar");
      document.body.dir = "rtl";
      document.documentElement.setAttribute("lang", "ar");
      setLang("ar");
      localStorage.setItem('lang', 'ar');
    } else {
      i18n.changeLanguage("en");
      document.body.dir = "ltr";
      document.documentElement.setAttribute("lang", "en");
      setLang("en");
      localStorage.setItem('lang', 'en');

    }
  };
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  const [islogedin, setislogedin] = useState(false);
  const [activeSection, setactiveSection] = useState("");
  const [showburger, setshowburger] = useState(false);
  const [profiledropdown, setprofiledropdown] = useState(false);

  const [search, setsearch] = useState(false);
  const [searchdata, setsearchdata] = useState("");

  const headerOptions = [
    lang === "en" ? "Know your major" : " استكشف اختصاصك",
    lang === "en" ? "Schools maps" : " خارطة المدارس",
    lang === "en" ? "Understand TVET" : "مسار التعليم المهني",
    lang === "en" ? "Platforms" : "المنصات",
  ];

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (profiledropdown && ref.current && !ref.current.contains(e.target)) {
        setprofiledropdown(!profiledropdown);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [profiledropdown]);

  const handleOnClick = (section) => {
    setactiveSection(section);
    if (section === "Know your major" || section === " استكشف اختصاصك") {
      navigate("/majordetails");
    }
    if (section === "Schools maps" || section === " خارطة المدارس") {
      navigate("/schoolsmap");
    }
    if (section === "Understand TVET" || section === "مسار التعليم المهني") {
      navigate("/tvet");
    }
  };

  const handleOnMouseLeave = () => {
    setactiveSection(null);
  };
  const handleOnMouseEnter = (section) => {
    setactiveSection(section);
  };

  return (
    <div className="Main_header_div">
      <div
        className="Header_container"
        onMouseLeave={() => handleOnMouseLeave()}
      >
        <div
          className="Header_logo_container"
          onClick={() => navigate("/")}
          onMouseLeave={() => handleOnMouseLeave()}
        >
          <img
            src={logo}
            alt="logo"
            className="logo_Header"
            onMouseLeave={() => handleOnMouseLeave()}
          />
        </div>
        <div className="Header_nav_container">
          {headerOptions.map((section) => (
            <div
              key={section}
              className="Header_nav_item_wrapper"
              onMouseEnter={() => handleOnMouseEnter(section)}
            >
              <a
                className={`Header_nav_item ${
                  activeSection === section ? "active" : ""
                }`}
                onClick={() => handleOnClick(section)}
              >
                {section}
              </a>

              {(activeSection === "Platforms" || activeSection === "المنصات") &&
                (section === "Platforms" || section === "المنصات") && (
                  <div
                    className="Header_nav_dropdown_2"
                    onMouseLeave={() => handleOnMouseLeave()}
                  >
                    <div className="Header_nav_dropdown_cont">
                      <div className="subsection_text">
                        <a
                          href="https://jobs.mehnati.org/"
                          target="_blank"
                        >
                          {" "}
                          Job Portal
                        </a>
                      </div>
                      <div className="subsection_text">
                        <a href="https://lms.mehnati.org/"> LMS</a>
                      </div>
                      <div className="subsection_text">
                        <a
                          href="https://mehnati.org/" 
                          target="_blank"
                        >
                          {" "}
                          Mehnati
                        </a>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          ))}
        </div>

        <div className="endinheader">
          <div className="header_translation ">
            <div
              onClick={handleLanguageChangear}
              className={
                lang == "ar"
                  ? "translation_section translation_section_arabic"
                  : "translation_section"
              }
            >
              <span
                className={
                  lang === "en"
                    ? "language-selected active-lang"
                    : "language-selected "
                }
              >
                EN
              </span>
              |
              <span
                className={
                  lang === "ar"
                    ? "language-selected active-lang"
                    : "language-selected "
                }
              >
                ع
              </span>
            </div>
          </div>
        </div>

        <div
          className="Header_burger_menu"
          onClick={() => setshowburger(!showburger)}
        >
          <RxHamburgerMenu className="Header_burger_icon" size={30} />
        </div>
      </div>
      {showburger && (
        <BurgerMenu isShown={showburger} setIsShown={setshowburger} />
      )}
    </div>
  );
}

export default Header;
