import React, { useState, useEffect, useRef } from "react";
import "./SchooolMap.css";
import { useDispatch, useSelector } from "react-redux";
import imagehead from "./../../assets/images/singlepage/headingc.png";

import { IoIosArrowForward } from "react-icons/io";
import { BsFillTrashFill } from "react-icons/bs";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { GrSearch } from "react-icons/gr";
import {
  getLevel,
  getMajor,
  getSchoool,
  getDistrict,
} from "./../../Redux/Schools/SchoolAction";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useSSR, useTranslation } from "react-i18next";
import i18n from "../../i18n";
function SchoolMap() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);
  const { majors, level, error1, error2, schools, districts, loading3 } =
    useSelector((store) => store.SchoolReducer);
  useEffect(() => {
    dispatch(getLevel());
    dispatch(getDistrict());

    dispatch(getMajor());
  }, []);

  const [leveldata, setLeveldata] = useState("");
  const [majordata, setMajordata] = useState("");
  const [schooldata, setSchooldata] = useState("");

  const [districttype, setDistricttype] = useState("");
  useEffect(() => {
    if (level) {
      setLeveldata(level);
    }
  }, [level]);
  useEffect(() => {
    if (majors) {
      setMajordata(majors);
    }
  }, [majors]);
  useEffect(() => {
    if (districts) {
      setDistricttype(districts?.data?.data);
    }
  }, [districts]);

  const [search, setSearch] = useState("");
  const [Categories, setCategories] = useState(false);
  const [jobtypes, setjobtypes] = useState(false);
  const [district, setDistrict] = useState(false);
  const [selecteddistrict, setselecteddistrict] = useState(null);
  const [educationallevellist, seteducationallevellist] = useState([]);
  const [selectededucationallevel, setselectededucationallevel] =
    useState(null);
  const [education, setEducation] = useState(false);

  const handleCategories = () => {
    setCategories(!Categories);
    setDistrict(null);
    setjobtypes(null);
    setEducation(null);
  };
  const handletype = () => {
    setjobtypes(!jobtypes);
    setCategories(null);
    setDistrict(null);

    setEducation(null);
  };
  const handledistrict = () => {
    setDistrict(!district);
    setjobtypes(null);
    setCategories(null);

    setEducation(null);
  };
  const handleeducation = () => {
    setEducation(!education);
    setDistrict(null);
    setjobtypes(null);
    setCategories(null);
  };

  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleCategorySelection = (categoryId) => {
    setSelectedCategory(categoryId === selectedCategory ? null : categoryId);
  };
  const [selectedtype, setSelectedtype] = useState(null);

  const handletypeSelection = (typeName) => {
    if (selectedtype === typeName) {
      setSelectedtype(""); // Clear the selected type name
    } else {
      setSelectedtype(typeName); // Set the selected type name
    }
  };

  const handledistrictSelection = (typeName) => {
    if (selecteddistrict === typeName) {
      setselecteddistrict(""); // Clear the selected type name
    } else {
      setselecteddistrict(typeName); // Set the selected type name
    }
  };
  const handleeducationSelection = (typeName) => {
    if (selectededucationallevel === typeName) {
      setselectededucationallevel(""); // Clear the selected type name
    } else {
      setselectededucationallevel(typeName); // Set the selected type name
    }
  };

  const sector = [
    { id: 1, name: "Public", name_ar: "القطاع العام" },
    { id: 2, name: "Private", name_ar: "القطاع الخاص" },
  ];
  const handlereset = () => {
    setSelectedCategory("");
    setSelectedtype("");
    setselectededucationallevel("");
    setselecteddistrict("");
    setSearch("");
    setDistrict(null);
    setjobtypes(null);
    setCategories(null);
    setEducation(null);
    setSearch("");
  };
  const position = {
    lat: 33.8938,
    lng: 35.5018,
  };
  useEffect(() => {
    dispatch(
      getSchoool(
        selectedCategory,
        selecteddistrict,
        selectedtype,
        selectededucationallevel,
        search
      )
    );
  }, [
    dispatch,
    selectedCategory,
    selecteddistrict,
    selectedtype,
    selectededucationallevel,
    search,
  ]);
  useEffect(() => {
    if (schools) {
      setSchooldata(schools?.data);
    }
  }, [schools]);
  const [selectedSchool, setSelectedSchool] = useState([]);

  const handleMarkerClick = (school) => {
    setSelectedSchool(school);
  };

  const BlueLoader = () => {
    return (
      <div className="blue-loader-container">
        <div className="blue-loader"></div>
      </div>
    );
  };

  return (
    <div className="Mappage-container">
      <div className="image-head-map1">
        <div className="Title-map">
          {" "}
          {lang == "en" && <div> Schools Map </div>}
          {lang == "ar" && <div> خارطة المدارس</div>}
        </div>
        <img src={imagehead} alt="imagehead" className="image-head-map" />
      </div>
      <div className="filter_bar">
        <div className="upperfilters_box">
          <div className="search_box">
            <label className="cont_search">
              <GrSearch size={30} className="search_icon" />
              <input
                type="text"
                className="input_complete_search"
                value={search}
                placeholder={
                  lang == "en" ? "Search for schools" : "ابحث عن المدرسة"
                }
                onChange={(event) => setSearch(event.target.value)}
              />
            </label>
          </div>
        </div>
        <div className="filter_icons_container">
          <div className="icons_drop">
            <div className="icons_dropdown">
              <div className="title_drop_cont" onClick={handleCategories}>
                <div className="title_drop">
                  {lang == "en" && <div> Educational Level</div>}
                  {lang == "ar" && <div> المستوى التعليمي</div>}
                </div>
                <div className="arrow_cont">
                  <IoIosArrowForward
                    size={30}
                    className={Categories ? "arrow_icon_rot" : "arrow_icon"}
                  />
                </div>
              </div>
              {Categories && (
                <div className="Header_nav_dropdown_job">
                  <div className="Header_nav_dropdown_cont">
                    {leveldata?.data?.data.map((category) => (
                      <div key={category.id} className="subsection_text_jobs">
                        <input
                          type="checkbox"
                          value={category.id}
                          name="category_filter"
                          className="checkbox_filter"
                          id={`category_${category.id}`}
                          checked={selectedCategory === category.id}
                          onChange={() => {
                            handleCategorySelection(category.id);
                          }}
                        />
                        <label htmlFor={`category_${category.id}`}>
                          {lang == "en" && (
                            <div style={{ width: "15rem" }}>
                              {" "}
                              {category?.name_en}{" "}
                            </div>
                          )}
                          {lang == "ar" && (
                            <div style={{ width: "15rem" }}>
                              {" "}
                              {category?.name_ar}{" "}
                            </div>
                          )}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className="icons_dropdown">
              <div className="title_drop_cont" onClick={handletype}>
                <div className="title_drop">
                  {lang == "en" && <div> Sector</div>}
                  {lang == "ar" && <div> القطاع</div>}
                </div>
                <div className="arrow_cont">
                  <IoIosArrowForward
                    size={30}
                    className={jobtypes ? "arrow_icon_rot" : "arrow_icon"}
                  />
                </div>
              </div>
              {jobtypes && (
                <div className="Header_nav_dropdown_job_2">
                  <div className="Header_nav_dropdown_cont">
                    {/* <div className="subsection_text">Sucess Stories</div>
                      <div className="subsection_text">Media Content</div>
                      <div className="subsection_text">Q&A </div> */}
                    {/* {categorylist.map((item) => (
                        <div className="subsection_text_jobs" key={item.id}>
                          {" "}
                          <input
                            type="checkbox"
                            className="checkbox_filter"
                            value={categoriesfilter}
                            name="category_filter"
                            id={`category_${item.id}`}
                            onChange={(e) =>
                              setcategoriesfilter(e.target.value)
                            }
                          />
                          <label htmlFor={`category_${item.id}`}>
                            {item.name_en}{" "}
                          </label>
                        </div>
                      ))} */}
                    {sector?.map((type) => (
                      <div key={type.id} className="subsection_text_jobs">
                        <input
                          type="checkbox"
                          value={type.name} // Store the name instead of the ID
                          name="category_filter"
                          className="checkbox_filter"
                          id={`type_${type.id}`}
                          checked={selectedtype === type.name} // Compare with selectedTypeName
                          onChange={() => handletypeSelection(type.name)} // Pass the name to handletypeSelection
                        />
                        <label htmlFor={`type_${type.id}`}>
                          {lang == "en" && <div> {type.name}</div>}
                          {lang == "ar" && <div> {type.name_ar}</div>}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="icons_dropdown">
              <div className="title_drop_cont" onClick={handledistrict}>
                <div className="title_drop">
                  {" "}
                  {lang == "en" && <div> District</div>}
                  {lang == "ar" && <div> المنطقة</div>}
                </div>
                <div className="arrow_cont">
                  <IoIosArrowForward
                    size={30}
                    className={district ? "arrow_icon_rot" : "arrow_icon"}
                  />
                </div>
              </div>
              {district && (
                <div className="Header_nav_dropdown_job_2">
                  <div className="Header_nav_dropdown_cont">
                    {/* <div className="subsection_text">Sucess Stories</div>
                      <div className="subsection_text">Media Content</div>
                      <div className="subsection_text">Q&A </div> */}
                    {/* {categorylist.map((item) => (
                        <div className="subsection_text_jobs" key={item.id}>
                          {" "}
                          <input
                            type="checkbox"
                            className="checkbox_filter"
                            value={categoriesfilter}
                            name="category_filter"
                            id={`category_${item.id}`}
                            onChange={(e) =>
                              setcategoriesfilter(e.target.value)
                            }
                          />
                          <label htmlFor={`category_${item.id}`}>
                            {item.name_en}{" "}
                          </label>
                        </div>
                      ))} */}
                    {districttype.map((type) => (
                      <div key={type.id} className="subsection_text_jobs">
                        <input
                          type="checkbox"
                          value={type.name} // Store the name instead of the ID
                          name="category_filter"
                          className="checkbox_filter"
                          id={`type_${type.id}`}
                          checked={selecteddistrict === type.id} // Compare with selectedTypeid
                          onChange={() => handledistrictSelection(type.id)} // Pass the name to handletypeSelection
                        />
                        <label htmlFor={`type_${type.id}`}>{type.name}</label>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="icons_dropdown">
              <div className="title_drop_cont" onClick={handleeducation}>
                <div className="title_drop">
                  {lang == "en" && <div> Major </div>}
                  {lang == "ar" && <div> الاختصاص</div>}
                </div>
                <div className="arrow_cont">
                  <IoIosArrowForward
                    size={30}
                    className={education ? "arrow_icon_rot" : "arrow_icon"}
                  />
                </div>
              </div>
              {education && (
                <div className="Header_nav_dropdown_job_2">
                  <div className="Header_nav_dropdown_cont">
                    {/* <div className="subsection_text">Sucess Stories</div>
                      <div className="subsection_text">Media Content</div>
                      <div className="subsection_text">Q&A </div> */}
                    {/* {categorylist.map((item) => (
                        <div className="subsection_text_jobs" key={item.id}>
                          {" "}
                          <input
                            type="checkbox"
                            className="checkbox_filter"
                            value={categoriesfilter}
                            name="category_filter"
                            id={`category_${item.id}`}
                            onChange={(e) =>
                              setcategoriesfilter(e.target.value)
                            }
                          />
                          <label htmlFor={`category_${item.id}`}>
                            {item.name_en}{" "}
                          </label>
                        </div>
                      ))} */}

                    {majordata?.data?.data?.map((type) => (
                      <div key={type.id} className="subsection_text_jobs">
                        <input
                          type="checkbox"
                          value={type.name} // Store the name instead of the ID
                          name="category_filter"
                          className="checkbox_filter"
                          id={`type_${type.id}`}
                          checked={selectededucationallevel === type.id} // Compare with selectedTypeName
                          onChange={() => handleeducationSelection(type.id)} // Pass the name to handletypeSelection
                        />
                        <label htmlFor={`type_${type.id}`}>
                          {lang == "en" && <div> {type.name_en} </div>}
                          {lang == "ar" && <div> {type?.name_ar}</div>}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className="title_drop_cont" onClick={handlereset}>
              <div className="trash_cont">
                <BsFillTrashFill size={30} className="trash_icon" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="Map_container">
        <LoadScript googleMapsApiKey="AIzaSyAEmTg3qjxS9QYc7YcWggM_YiqX6QW7DDU">
          <GoogleMap
            className="Map"
            center={{ lat: 33.8938, lng: 35.5018 }}
            zoom={8}
            mapContainerStyle={{ height: "600px" }}
          >
            {schooldata?.data?.map((location, index) => (
              <Marker
                key={location.id}
                position={{
                  lat: parseFloat(location.location_latitude),
                  lng: parseFloat(location.location_longitute),
                }}
                onClick={() => handleMarkerClick(schooldata.data[index])}
              />
            ))}
            {selectedSchool?.id && (
              <InfoWindow
                position={{
                  lat: parseFloat(selectedSchool.location_latitude) + 0.001,
                  lng: parseFloat(selectedSchool.location_longitute),
                }}
                onCloseClick={() => setSelectedSchool(null)}
              >
                <div className="modal-map">
                  <div className="Header-modal">
                    <img
                      className="logo-modal"
                      src={
                        process.env.REACT_APP_API +
                        "/school/" +
                        selectedSchool.logo_url
                      }
                    />

                    <div className="title-modal">
                      {lang == "en" && (
                        <div> {selectedSchool.institution_name_en}</div>
                      )}
                      {lang == "ar" && (
                        <div> {selectedSchool.institution_name_ar}</div>
                      )}
                    </div>
                  </div>
                  <br></br>
                  <br></br>

                  <div className="modalmpa-item">
                    {lang == "en" && (
                      <div> Sector : {selectedSchool.institution_type_en}</div>
                    )}
                    {lang == "ar" && (
                      <div>القطاع : {selectedSchool.institution_type_ar} </div>
                    )}
                  </div>
                  <div className="modalmpa-item">
                    {lang == "en" && (
                      <div>Email Address : {selectedSchool.email}</div>
                    )}
                    {lang == "ar" && (
                      <div>الايميل : {selectedSchool.email}</div>
                    )}
                  </div>
                  <div className="modalmpa-item">
                    {lang == "en" && (
                      <div>Phone Number : {selectedSchool.phone}</div>
                    )}
                    {lang == "ar" && <div>الهاتف : {selectedSchool.phone}</div>}
                  </div>

                  <div className="modalmpa-item">
                    {" "}
                    <Link to={`/singlepag/${selectedSchool.id}`}>
                      {lang == "en" && <div> Read more</div>}
                      {lang == "ar" && <div> قراءة المزيد </div>}
                    </Link>
                  </div>
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
        </LoadScript>
      </div>
    </div>
  );
}

export default SchoolMap;
