import axios from "axios";
import { SchoolsAction } from "./SchoolReducer";

export const getLevel = () => async (dispatch) => {
  try {
    dispatch(SchoolsAction.levelRequest());
    const res = await axios.get(process.env.REACT_APP_API + "/level");
    dispatch(SchoolsAction.levelSuccess(res));
  } catch (error) {
    dispatch(SchoolsAction.levelFail(error.message));
  }
};

export const getStat = () => async (dispatch) => {
  try {
    dispatch(SchoolsAction.statRequest());
    const res = await axios.get(process.env.REACT_APP_API + "/");
    dispatch(SchoolsAction.statSuccess(res));
  } catch (error) {
    dispatch(SchoolsAction.statFail(error.message));
  }
};

export const getDistrict = () => async (dispatch) => {
  try {
    dispatch(SchoolsAction.districtRequest());
    const res = await axios.get(process.env.REACT_APP_API + "/district");
    dispatch(SchoolsAction.districtSuccess(res));
  } catch (error) {
    dispatch(SchoolsAction.districtFail(error.message));
  }
};

export const getMajor = () => async (dispatch) => {
  try {
    dispatch(SchoolsAction.majorRequest());
    const res = await axios.get(process.env.REACT_APP_API + "/major");
    dispatch(SchoolsAction.majorSuccess(res));
  } catch (error) {
    dispatch(SchoolsAction.majorFail(error.message));
  }
};
export const getSchoool =
  (level_id, district_id, sector, major_id, search) => async (dispatch) => {
    try {
      dispatch(SchoolsAction.schoolRequest());
      let uri = process.env.REACT_APP_API + "/school/getAll?"; // Changed to 'let' instead of 'const'
      if (level_id) uri += "level_id=" + level_id + "&";
      if (district_id) uri += "district_id=" + district_id + "&";
      if (sector) uri += "sector=" + sector + "&";
      if (major_id) uri += "major_id=" + major_id + "&";
      if (search) uri += "search=" + search;

      const res = await axios.get(uri);
      dispatch(SchoolsAction.schoolSuccess(res));
    } catch (error) {
      dispatch(SchoolsAction.schoolFail(error.message));
    }
  };
export const getgallerySchool = (id) => async (dispatch) => {
  try {
    dispatch(SchoolsAction.getgalleryRequest());

    const res = await axios.get(
      process.env.REACT_APP_API + "/gallery/getOne/" + id
    );

    dispatch(SchoolsAction.getgallerySuccess(res));
  } catch (error) {
    dispatch(SchoolsAction.getgalleryFailure(error.message));
  }
};
export const getSChool = (id) => async (dispatch) => {
  try {
    dispatch(SchoolsAction.oneschoolRequest());
    const res = await axios.get(
      process.env.REACT_APP_API + "/school/getOne/" + id
    );
    dispatch(SchoolsAction.oneschoolSuccess(res));
  } catch (error) {
    dispatch(SchoolsAction.oneschoolFail(error.message));
  }
};
